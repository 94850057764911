<template>
  <div class="flex w-full h-8 items-center z-10">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "GanttGridGroupBar",
  components: {},
  setup() {
    return {};
  }
});
</script>
