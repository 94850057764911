
import { defineComponent, computed, PropType } from "vue";
// components
import GanttGridTaskBar from "@/components/gantt/grid/GanttGridTaskBar.vue";
// store
import useScheduleStore from "@/store/useScheduleStore";
// other
import { GanttTask } from "@/components/gantt/useGantt";
import useTimeIntervals from "@/utils/useTimeIntervals";
import { addDate, parseTimeString, getTimeStringFromDate } from "@/utils/dateHelpers";

export default defineComponent({
  name: "DayViewWorkAreaGridTask",
  components: { GanttGridTaskBar },
  props: { workAreaTask: { type: Object as PropType<GanttTask>, required: true } },
  emits: ["coverage-array-updated"],

  setup(props, context) {
    const { timeIntervals, timeIntervalSize, getTimeIntervalIndexFromDate } = useTimeIntervals();
    const { selectedDate, onUpdateShift, onUpdateShiftSuccess } = useScheduleStore();
    // start interval of shift
    const taskStartIndex = computed(() => getTimeIntervalIndexFromDate(props.workAreaTask.data.start_datetime));
    // shift duration in intervals
    const taskDuration = computed(() => {
      const endIndex = getTimeIntervalIndexFromDate(props.workAreaTask.data.end_datetime);
      return endIndex - taskStartIndex.value;
    });

    // pass through coverage array update
    const onCoverageArrayUpdate = (coverageArray: number[]) => {
      context.emit("coverage-array-updated", coverageArray);
    };

    // update event
    const onCoverageArrayUpdateFinished = (startIndex: number, duration: number) => {
      // start date
      const newStartTime = getTimeStringFromDate(timeIntervals.value[startIndex - 1]);
      const newStartDate = parseTimeString(newStartTime, selectedDate.value);
      // end date, add 15 minutes to each duration as array does not cover last date
      const newEndTime = getTimeStringFromDate(
        addDate(timeIntervals.value[startIndex - 1 + (duration - 1)], {
          minutes: timeIntervalSize.value
        })
      );
      const newEndDate = parseTimeString(newEndTime, selectedDate.value);
      // update shift, use selected date
      onUpdateShift(newStartDate, newEndDate, props.workAreaTask.id);
    };

    onUpdateShiftSuccess(() => {
      console.info("shift updated");
    });

    return {
      onCoverageArrayUpdate,
      onCoverageArrayUpdateFinished,
      taskStartIndex,
      taskDuration
    };
  }
});
