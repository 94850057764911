
// components
import { computed, defineComponent, PropType, watch, onMounted, ref } from "vue";
import BaseInput from "@/components/base/BaseInput.vue";
import DatePicker from "@/components/datepicker/DatePicker.vue";
// other
import { StringSchema } from "yup";
import { createPopper } from "@popperjs/core";
import { formatDate } from "@/utils/dateHelpers";

export default defineComponent({
  name: "DatePickerInput",
  components: {
    BaseInput,
    DatePicker
  },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    buttonOnly: {
      type: Boolean,
      default: false
    },
    validate: {
      type: Boolean,
      default: false
    },
    schema: { type: (Object as PropType<StringSchema>) || null, default: null },
    initialDate: {
      default: null,
      type: Object as PropType<Date>
    },
    focusMonth: {
      default: new Date(),
      type: Object as PropType<Date>
    },
    lastDisabledDate: {
      default: null,
      type: Object as PropType<Date>
    },
    firstDisabledDate: {
      default: null,
      type: Object as PropType<Date>
    }
  },
  emits: {
    "select-date": null
  },
  setup(props, context) {
    // input and date picker ids
    const datePickerId = props.name + "date-picker";
    const datePickerInputId = props.name + "date-picker-input";

    // VISIBILITY
    const datePickerVisible = ref(false);
    const onCloseDatePicker = () => (datePickerVisible.value = false);

    // SELECTION
    const selectedDate = ref(props.initialDate);

    // change selected date
    watch(
      () => props.initialDate,
      curr => {
        selectedDate.value = curr;
      }
    );

    // needed to position popper correctly after initial load
    // https://stackoverflow.com/questions/55524899/tooltip-popper-js-resize-after-adding-tooltip-title-dynamically
    watch(datePickerVisible, () => {
      window.dispatchEvent(new Event("resize"));
    });

    const onSelectDate = (date: Date) => {
      selectedDate.value = date;
      datePickerVisible.value = false;
      context.emit("select-date", date);
    };

    // Calendar positioning with popper
    onMounted(() => {
      const inputElement = document.getElementById(datePickerInputId);
      const datePickerElement = document.getElementById(datePickerId);
      if (inputElement != null && datePickerElement != null) {
        createPopper(inputElement, datePickerElement, {
          placement: "bottom-start",
          strategy: "fixed",
          modifiers: [
            {
              name: "offset",
              options: {
                // compensate the padding around the calendar
                offset: [0, 5]
              }
            }
          ]
        });
      }
    });

    const inputValue = computed(() => {
      if (selectedDate.value === null) {
        return "";
      } else {
        return formatDate(selectedDate.value);
      }
    });

    return {
      datePickerId,
      datePickerInputId,
      formatDate,
      inputValue,
      onSelectDate,
      datePickerVisible,
      onCloseDatePicker,
      selectedDate
    };
  }
});
