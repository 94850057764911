<template>
  <DayViewWorkAreaGridGroup v-for="group in workAreaGroups" :key="group.id" :workAreaGroup="group" />
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
// components
import DayViewWorkAreaGridGroup from "@/features/schedules/dayView/workArea/grid/DayViewWorkAreaGridGroup.vue";
// store
import useScheduleStore from "@/store/useScheduleStore";
// other
import useGantt from "@/components/gantt/useGantt";

export default defineComponent({
  name: "DayViewWorkAreaGrid",
  components: { DayViewWorkAreaGridGroup },
  setup() {
    const { groups } = useGantt();
    const { demands } = useScheduleStore();

    // filter groups that have no demand
    const workAreaGroups = computed(() =>
      groups.value.filter(group => {
        const demandList = demands.value?.filter(demand => demand.work_area_id == group.id);
        if (demandList.length > 0) return true;
        else return false;
      })
    );

    return { workAreaGroups };
  }
});
</script>
