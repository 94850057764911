<template>
  <GanttGridGroupBar>
    <div
      class="relative w-full grid select-none"
      :style="{
        'grid-template-columns': `repeat(${gridSize}, minmax(${gridColWidth}, 1fr))`
      }"
    >
      <div v-for="(i, index) in demandArray" :key="index">
        <DayViewWorkAreaGridGroupBarItem
          :demand="i"
          :coverage="coverageArray[index]"
          :isFirstItem="index === 0"
          :isLastItem="index === demandArray.length - 1"
        />
      </div>
    </div>
  </GanttGridGroupBar>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "vue";
// components
import GanttGridGroupBar from "@/components/gantt/grid/GanttGridGroupBar.vue";
import DayViewWorkAreaGridGroupBarItem from "./DayViewWorkAreaGridGroupBarItem.vue";
// store
import useScheduleStore from "@/store/useScheduleStore";
// other
import useGantt, { GanttGroup } from "@/components/gantt/useGantt";
import useTimeIntervals from "@/utils/useTimeIntervals";
import { getTimeStringFromDate } from "@/utils/dateHelpers";
import { createAccumulatedDemandArray, createDemandArrayObject } from "@/features/demand/useDemand";

export default defineComponent({
  name: "DayViewWorkAreaGridGroup",
  components: { GanttGridGroupBar, DayViewWorkAreaGridGroupBarItem },
  props: {
    workAreaGroup: { type: Object as PropType<GanttGroup>, required: true },
    coverageArray: {
      required: true,
      default: () => [] // error if instead "type: Array"
    }
  },
  setup(props) {
    // components
    const { gridSize, gridColWidth } = useGantt();
    const { startTime, timeIntervalSize, timeIntervals } = useTimeIntervals();
    const { demands } = useScheduleStore();

    // get all demands for work area on selected day
    const workAreaDemandList = computed(() => {
      return demands.value?.filter(demand => demand.work_area_id == props.workAreaGroup.id);
    });

    // create demand objects
    const workAreaDemandObjectList = computed(() =>
      workAreaDemandList.value?.map(workAreaDemand => {
        return createDemandArrayObject({
          demandStartTime: getTimeStringFromDate(workAreaDemand.start_datetime),
          demandEndTime: getTimeStringFromDate(workAreaDemand.end_datetime),
          demandAmount: workAreaDemand.amount,
          dayStartTime: startTime.value,
          timeIntervalSize: timeIntervalSize.value,
          timeIntervalCount: timeIntervals.value.length
        });
      })
    );

    // merge demand arrays to one array
    const demandArray = computed(() => createAccumulatedDemandArray(workAreaDemandObjectList.value));

    return {
      demandArray,
      gridSize,
      gridColWidth
    };
  }
});
</script>
