
import { defineComponent, computed, watch } from "vue";
import { useRoute } from "vue-router";
import PageLayout from "@/components/PageLayout.vue";
import ScheduleCalendar from "@/features/schedules/ScheduleCalendar.vue";
// import WorkAreaDayView from "@/features/schedules/workAreaDayView/WorkAreaDayView.vue";
// store
import useScheduleStore from "@/store/useScheduleStore";
// other
import { repairUuid } from "@/utils/globalHelpers";

export default defineComponent({
  name: "SchedulePlanningPage",
  components: {
    PageLayout,
    ScheduleCalendar
  },
  setup() {
    // schedule id from link
    const {
      params: { scheduleId: scheduleUrlId }
    } = useRoute();
    const scheduleId = computed(() => repairUuid(scheduleUrlId));
    // store
    const { schedule, setSelectedSchedule } = useScheduleStore({ scheduleId: scheduleId.value });

    // set selected schedule
    if (schedule.value) setSelectedSchedule(schedule.value);
    watch(schedule, () => {
      if (schedule.value) setSelectedSchedule(schedule.value);
    });

    // get previous route, that was not schedule details related
    const prevRoute = computed(() => {
      if (window.history.state.back === null || window.history.state.back.includes("new")) {
        return "/schedules";
      } else return window.history.state.back;
    });

    const headerText = computed(() => {
      if (schedule.value) return schedule.value.name;
      else return "";
    });

    return {
      headerText,
      scheduleId,
      prevRoute,
      schedule
    };
  }
});
