<template>
  <div
    class="relative w-full grid select-none"
    :style="{
      'grid-template-columns': `repeat(${gridSize}, minmax(${gridColWidth}, 1fr))`
    }"
  >
    <div
      ref="item"
      class="flex z-10 h-6 rounded-md justify-between border"
      :class="[bgColorClasses]"
      :style="{
        'grid-column': `${startCol} / span ${colSpan}`
      }"
      @mouseover="showDragHandles = true"
      @mouseleave="showDragHandles = true"
    >
      <!-- Drag handle left -->
      <div v-show="showDragHandles === true" class="flex cursor-col-resize" @mousedown="onResize('left', $event)">
        <div :class="[dragHandleDividerClasses, dragHandleSpaceClasses]" class="border-r"></div>
      </div>
      <!-- Body -->
      <div class="w-full cursor-move" :class="{ 'mx-2': newSpan > 1 }" @mousedown="onMove"></div>
      <!-- Drag handle right -->
      <div v-show="showDragHandles === true" class="flex cursor-col-resize" @mousedown="onResize('right', $event)">
        <div :class="[dragHandleDividerClasses, dragHandleSpaceClasses]" class="border-l"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch } from "vue";
import useGanttTask from "@/components/gantt/useGanttTask";
import useGantt from "@/components/gantt/useGantt";

export default defineComponent({
  name: "GanttGridTaskBar",
  props: {
    startTime: {
      type: Number,
      required: true
    },
    duration: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      default: "blue"
    }
  },
  emits: ["coverage-array-updated", "coverage-array-update-finished"],
  setup(props, context) {
    const { gridSize, gridColWidth } = useGantt();

    // task div
    const item = ref<HTMLElement | null>(null);
    // start and duration of task
    const startCol = ref(props.startTime);
    const colSpan = ref(props.duration);

    const showDragHandles = ref(true);

    /**
     * Drag and drop
     */

    // resize hook
    const { onResize, onMove, coverageArray, newSpan, updateFinished } = useGanttTask(item, {
      gridSize,
      startCol,
      colSpan
    });

    // emit coverage array updates
    watch(coverageArray, curr => {
      context.emit("coverage-array-updated", curr);
    });

    // emit update finished
    watch(updateFinished, () => {
      if (props.startTime != startCol.value || props.duration != colSpan.value) {
        context.emit("coverage-array-update-finished", startCol.value, colSpan.value);
      }
    });

    /**
     * Styles
     */

    // task color
    const bgColorClasses = computed(() => {
      return `bg-${props.color}-100 border-${props.color}-500`;
    });
    // task drag handle colors
    const dragHandleDividerClasses = computed(() => {
      return `border-${props.color}-500 my-1`;
    });

    // task drag handle colors
    const dragHandleSpaceClasses = computed(() => {
      return {
        "w-2": newSpan.value >= 2,
        "w-1": newSpan.value === 1
      };
    });

    return {
      newSpan,
      gridSize,
      gridColWidth,
      item,
      onResize,
      onMove,
      bgColorClasses,
      dragHandleDividerClasses,
      dragHandleSpaceClasses,
      startCol,
      colSpan,
      showDragHandles
    };
  }
});
</script>

<style scoped></style>
