<template>
  <GanttGridGroup :isCollapsed="workAreaGroup.isCollapsed">
    <template #group-bar>
      <DayViewWorkAreaGridGroupBar :workAreaGroup="workAreaGroup" :coverageArray="coverageArray" />
    </template>
    <template #task-bars>
      <DayViewWorkAreaGridTaskBar
        v-for="(task, index) in workAreaGroup.tasks"
        :key="task.id"
        :workAreaTask="task"
        @coverage-array-updated="onCoverageArrayUpdate(index, $event)"
      />
    </template>
  </GanttGridGroup>
</template>

<script lang="ts">
import { defineComponent, computed, PropType, ref, watch } from "vue";
// components
import GanttGridGroup from "@/components/gantt/grid/GanttGridGroup.vue";
import DayViewWorkAreaGridGroupBar from "./DayViewWorkAreaGridGroupBar.vue";
import DayViewWorkAreaGridTaskBar from "./DayViewWorkAreaGridTaskBar.vue";
// other
import { GanttGroup } from "@/components/gantt/useGantt";
import { createCoverageArray } from "@/components/gantt/useGanttTask";
import useTimeIntervals from "@/utils/useTimeIntervals";
import { addArrays } from "@/utils/arrayHelpers";

export default defineComponent({
  name: "DayViewWorkAreaGridGroup",
  components: { GanttGridGroup, DayViewWorkAreaGridGroupBar, DayViewWorkAreaGridTaskBar },
  props: {
    workAreaGroup: { type: Object as PropType<GanttGroup>, required: true }
  },
  setup(props) {
    const { timeIntervals, getTimeIntervalIndexFromDate } = useTimeIntervals();

    /**
     * Coverage array
     */

    const createCoverageArrayList = () => {
      return props.workAreaGroup.tasks.map(task => {
        // get data for coverage array
        const startIndex = getTimeIntervalIndexFromDate(task.data.start_datetime);
        const endIndex = getTimeIntervalIndexFromDate(task.data.end_datetime);
        const duration = endIndex - startIndex;
        // create array
        const coveraveArray = createCoverageArray(timeIntervals.value.length, startIndex, duration);
        return coveraveArray;
      });
    };

    // create initial coverage arrays for each task
    const coverageArrayList = ref(createCoverageArrayList());

    watch(
      () => props.workAreaGroup.tasks,
      () => {
        coverageArrayList.value = createCoverageArrayList();
      }
    );

    // merge coverage arrays
    // return dummy array if no tasks are present
    const coverageArray = computed(() => {
      const addedArray = addArrays(coverageArrayList.value);
      if (addedArray.length > 0) return addedArray;
      else return new Array(timeIntervals.value.length).fill(0);
    });

    // update coverage array
    const onCoverageArrayUpdate = (index: number, coverageArray: number[]) => {
      coverageArrayList.value[index] = coverageArray;
    };

    return {
      onCoverageArrayUpdate,
      coverageArray
    };
  }
});
</script>
