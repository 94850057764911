<template>
  <div :class="rangeClasses">
    <button
      type="button"
      :class="dayClasses"
      class="flex w-10 h-10 justify-center items-center rounded-full select-none cursor-default border border-white focus:outline-none"
      @click="onClickDate"
      :disabled="isDisabled"
    >
      <div class="text-sm">
        {{formatDate(date, "d")}}
      </div>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "vue";
import {
  formatDate,
  checkSameDate,
  checkBeforeAfter,
} from "@/utils/dateHelpers";

export default defineComponent({
  name: "DatePickerDay",
  props: {
    date: { type: Object as PropType<Date>, required: true },
    selectedDate: {
      default: null,
      type: Object as PropType<Date>,
    },
    selectedMonth: { type: Object as PropType<Date>, required: true },
    firstDisabledDate: {
      default: null,
      type: Object as PropType<Date>,
    },
    lastDisabledDate: {
      default: null,
      type: Object as PropType<Date>,
    },
  },
  emits: {
    "click-date": null,
  },
  setup(props, context) {
    // Check day status
    const isSameMonth = computed(() =>
      checkSameDate(props.date, props.selectedMonth, "month")
    );

    const isSelected = computed(() => {
      if (props.selectedDate === null) {
        return false;
      } else {
        return checkSameDate(props.date, props.selectedDate, "day");
      }
    });

    const isDisabled = computed(() => {
      // not disabled if no date is given
      if (props.firstDisabledDate === null && props.lastDisabledDate === null) {
        return false;
        // Check if date is before first disabled date
      } else if (props.firstDisabledDate !== null) {
        return !checkBeforeAfter(props.date, props.firstDisabledDate, "before");
        // Check if date is after last disabled date
      } else if (props.lastDisabledDate !== null) {
        return !checkBeforeAfter(props.date, props.lastDisabledDate, "after");
      }
    });

    // click event
    const onClickDate = () => {
      context.emit("click-date");
    };

    // Create status for css classes
    const dayStatus = computed(() => {
      if (isDisabled.value === true) {
        return "disabled";
      } else if (isSameMonth.value === false) {
        return "otherMonth";
      } else if (isSelected.value === true) {
        return "selected";
      } else {
        return "default";
      }
    });

    const dayClasses = computed(() => {
      return {
        otherMonth:
          "text-gray-400 font-semibold hover:border-gray-900 active:text-gray-300 transition ease-in-out duration-150 cursor-pointer",
        default:
          "text-gray-900 font-semibold hover:border-gray-900 active:text-gray-500 transition ease-in-out duration-150 cursor-pointer",
        selected: "text-white bg-gray-900 font-bold cursor-pointer",
        disabled: "border-gray-100",
      }[dayStatus.value as "otherMonth" | "selected" | "default" | "disabled"];
    });

    const rangeClasses = computed(() => {
      return {
        default: "",
        disabled: "bg-gray-100 text-gray-400",
      }[dayStatus.value as "disabled" | "default"];
    });

    return {
      formatDate,
      isSameMonth,
      isDisabled,
      dayClasses,
      rangeClasses,
      onClickDate,
    };
  },
});
</script>

<style>
</style>