
import { defineComponent, PropType, ref } from "vue";
// components
import DatePickerInput from "@/components/datepicker/DatePickerInput.vue";
// other
import { addDate, subDate } from "@/utils/dateHelpers";

export default defineComponent({
  name: "CalendarHeaderNav",
  components: { DatePickerInput },
  props: {
    selectedDate: {
      default: null,
      type: Object as PropType<Date>
    },
    selectedView: {
      type: String,
      default: "day",
      validator: (prop: string) => ["day", "week", "month"].includes(prop)
    }
  },
  emits: ["date-updated", "view-updated"],
  setup(props, context) {
    // button styles
    const navButtonClasses =
      "relative inline-flex items-center justify-center border text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500";

    const navButtonDefaultClasses = "bg-white text-gray-500 border-gray-300 hover:bg-gray-50";
    const navButtonActiveClasses = "bg-blue-100 text-blue-700 border-transparent hover:bg-blue-200";

    // add one day to selected day
    const clickNext = () => {
      const newDate = ref(props.selectedDate);
      if (props.selectedView === "day") {
        newDate.value = addDate(props.selectedDate, { days: 1 });
      } else if (props.selectedView === "week") {
        newDate.value = addDate(props.selectedDate, { weeks: 1 });
      } else if (props.selectedView === "month") {
        newDate.value = addDate(props.selectedDate, { months: 1 });
      }
      context.emit("date-updated", newDate.value);
    };

    // subtract one day from selected day
    const clickPrev = () => {
      const newDate = ref(props.selectedDate);
      if (props.selectedView === "day") {
        newDate.value = subDate(props.selectedDate, { days: 1 });
      } else if (props.selectedView === "week") {
        newDate.value = subDate(props.selectedDate, { weeks: 1 });
      } else if (props.selectedView === "month") {
        newDate.value = subDate(props.selectedDate, { months: 1 });
      }
      context.emit("date-updated", newDate.value);
    };

    // select current date
    const clickToday = () => {
      context.emit("date-updated", new Date());
    };

    // select date with date picker
    const onSelectDate = (date: string) => {
      context.emit("date-updated", date);
    };

    // select view
    const selectView = (view: string) => {
      context.emit("view-updated", view);
    };

    return {
      navButtonClasses,
      navButtonDefaultClasses,
      navButtonActiveClasses,
      onSelectDate,
      clickNext,
      clickPrev,
      clickToday,
      selectView
    };
  }
});
