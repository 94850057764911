<template>
  <div class="flex flex-col space-y-2 pb-6">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "GanttList",
  components: {},
  props: {},
  setup() {
    return {};
  }
});
</script>
