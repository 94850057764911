<template>
  <div class="flex flex-col">
    <div class="flex items-center h-8 cursor-pointer bg-gray-100" @click="toggleGroupCollapse(group.id)">
      <svg
        class="ml-1 h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          v-if="!group.isCollapsed"
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
        <path
          v-if="group.isCollapsed"
          fill-rule="evenodd"
          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
          clip-rule="evenodd"
        />
      </svg>
      <p class="ml-2 select-none">{{ group.name }}</p>
    </div>
    <div v-show="!group.isCollapsed" class="py-2">
      <div class="space-y-2">
        <slot name="tasks"></slot>
      </div>

      <div class="mt-2">
        <slot name="menu"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
// other
import useGantt, { GanttGroup } from "@/components/gantt/useGantt";

export default defineComponent({
  name: "GanttListGroup",
  components: {},
  props: {
    group: { type: Object as PropType<GanttGroup>, required: true }
  },
  emits: ["toggle-collapse"],
  setup() {
    const { toggleGroupCollapse } = useGantt();

    return { toggleGroupCollapse };
  }
});
</script>
