import { computed } from "vue";
import useGantt, { GanttGroup, GanttTask } from "@/components/gantt/useGantt";
import useScheduleStore, { PlanningView } from "@/store/useScheduleStore";
import { createFullName } from "@/store/useEmployeeStore";
import { ScheduleWorkAreasView, ShiftFullFragment } from "@/graphql/types";

// export interface ScheduleDayViewState {
//   shifts: ShiftFullFragment[];
// }

// // Initialize time intercals state
// const state: ScheduleDayViewState = reactive({
//   shifts: []
// });

// create work area group
function createWorkAreaGroup(workArea: ScheduleWorkAreasView) {
  const workAreaGroup: GanttGroup = {
    id: workArea.id,
    name: workArea.name ? workArea.name : "",
    isCollapsed: false,
    tasks: [],
    data: workArea
  };
  return workAreaGroup;
}

// create work area group
export function createWorkAreaTask(shift: ShiftFullFragment) {
  const workAreaTask: GanttTask = {
    id: shift.id,
    name: createFullName(shift.employee.first_name, shift.employee.last_name),
    data: shift
  };
  return workAreaTask;
}

/**
 * ScheduleDayView Hook
 */
export default function useScheduleDayView() {
  // hooks
  const { workAreas, planningView } = useScheduleStore();
  const { setGanttGroups } = useGantt();

  /**
   * Create view groups
   */

  // create work area groups
  const workAreaGroups = computed(() => {
    if (workAreas.value) {
      return workAreas.value.map(workArea => createWorkAreaGroup(workArea));
    } else return [];
  });

  /**
   * Initialize/Set gantt groups
   */

  // initialize groups based on view
  const setPlanningViewGroups = (planningView: PlanningView) => {
    if (planningView == PlanningView.WorkAreas) setGanttGroups(workAreaGroups.value);
  };

  return {
    planningView,
    setPlanningViewGroups
  };
}
