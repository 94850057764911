<template>
  <div class="flex justify-between">
    <button
      type="button"
      class="flex justify-center items-center h-8 w-8 rounded-full bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 hover:bg-gray-50 focus:outline-none active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
      @click="onClickPrev"
    >
      <svg
        class="h-6 w-6 text-gray-400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
    <div class="flex items-center text-lg text-gray-900 font-medium">{{formatDate(selectedDate, "MMMM yyyy")}}</div>
    <button
      class="flex justify-center items-center h-8 w-8 rounded-full bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 hover:bg-gray-50 focus:outline-none active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
      type="button"
      @click="onClickNext"
    >
      <svg
        class="h-6 w-6 text-gray-400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
          clip-rule="evenodd"
        />
      </svg>

    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { formatDate } from "@/utils/dateHelpers";

export default defineComponent({
  name: "DatePickerHeader",
  props: {
    selectedDate: {
      type: Date,
      required: true,
    },
  },
  emits: {
    "click-next": null,
    "click-prev": null,
  },
  setup(props, context) {
    const onClickNext = () => context.emit("click-next");
    const onClickPrev = () => context.emit("click-prev");

    return { formatDate, onClickNext, onClickPrev };
  },
});
</script>

<style>
</style>