
import { defineComponent } from "vue";
import useGantt from "@/components/gantt/useGantt";
import useTimeIntervals from "@/utils/useTimeIntervals";

export default defineComponent({
  name: "GanttGridBorders",
  components: {},
  props: {},

  setup() {
    const { gridSize, gridColWidth } = useGantt();
    const { timeIntervalSize } = useTimeIntervals();

    // style borders differntly for full hours
    const timeIntervalHourRatio = 60 / timeIntervalSize.value;

    return { gridSize, gridColWidth, timeIntervalHourRatio };
  }
});
