
import { defineComponent, PropType, computed, ref, watch } from "vue";
import DatePickerDay from "./DatePickerDay.vue";
import DatePickerHeader from "./DatePickerHeader.vue";
import { formatDate, addDate, subDate, getWeekDays, getMonthWeeksStartDays } from "@/utils/dateHelpers";

export default defineComponent({
  name: "DatePicker",
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    initialDate: {
      default: null,
      type: Object as PropType<Date>
    },
    focusMonth: {
      default: null,
      type: Object as PropType<Date>
    },
    lastDisabledDate: {
      default: null,
      type: Object as PropType<Date>
    },
    firstDisabledDate: {
      default: null,
      type: Object as PropType<Date>
    }
  },
  components: {
    DatePickerDay,
    DatePickerHeader
  },
  emits: {
    "select-date": null
  },
  setup(props, context) {
    // initial dates
    const selectedDate = ref<null | Date>(props.initialDate);
    const selectedMonth = ref(props.focusMonth);

    if (selectedMonth.value === null) {
      selectedMonth.value = new Date();
    }

    // change initial month to be displayed in calendar
    watch(
      () => props.focusMonth,
      curr => {
        selectedMonth.value = curr;
      }
    );

    // change initial date to be displayed in calendar
    watch(
      () => props.initialDate,
      curr => {
        selectedDate.value = curr;
      }
    );

    // format week days string
    const initWeekDays = getWeekDays(selectedMonth.value);
    const days = initWeekDays.map((day: Date) => formatDate(day, "iiiiii"));
    // weeks of selected month
    const weeks = computed(() => getMonthWeeksStartDays(selectedMonth.value));

    // select date, change selected month if date outside current month is clicked
    const onSelectDate = (date: Date) => {
      selectedDate.value = date;
      selectedMonth.value = date;
      context.emit("select-date", date);
    };

    // month navigation
    const onSelectPrevMonth = () => (selectedMonth.value = subDate(selectedMonth.value, { months: 1 }));
    const onSelectNextMonth = () => (selectedMonth.value = addDate(selectedMonth.value, { months: 1 }));

    return {
      days,
      weeks,
      getWeekDays,
      getMonthWeeksStartDays,
      selectedMonth,
      selectedDate,
      onSelectPrevMonth,
      onSelectNextMonth,
      onSelectDate
    };
  }
});
