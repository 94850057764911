<template>
  <div
    class="absolute top-0 w-full h-full grid"
    :style="{
      'grid-template-columns': `repeat(${gridSize}, minmax(${gridColWidth}, 1fr))`
    }"
  >
    <div
      v-for="(i, index) in gridSize"
      :key="index"
      class="border-r border-gray-200"
      :class="{ 'border-gray-400': i % timeIntervalHourRatio === 0, 'border-gray-200': i % timeIntervalHourRatio != 0 }"
    ></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import useGantt from "@/components/gantt/useGantt";
import useTimeIntervals from "@/utils/useTimeIntervals";

export default defineComponent({
  name: "GanttGridBorders",
  components: {},
  props: {},

  setup() {
    const { gridSize, gridColWidth } = useGantt();
    const { timeIntervalSize } = useTimeIntervals();

    // style borders differntly for full hours
    const timeIntervalHourRatio = 60 / timeIntervalSize.value;

    return { gridSize, gridColWidth, timeIntervalHourRatio };
  }
});
</script>
