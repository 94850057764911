<template>
  <div class="flex flex-1 flex-col">
    <div class="flex w-full h-16 items-center justify-between">
      <div class="text-2xl ml-3">
        <div v-if="selectedView === 'day'">
          {{ formatDate(selectedDate, "EEEE, dd.MM.yyyy") }}
        </div>
        <div v-if="selectedView === 'week'">
          {{ formatDate(firstDayOfWeek, "dd.MM") }} - {{ formatDate(lastDayOfWeek, "dd.MM.yyyy") }}, KW
          {{ formatDate(lastDayOfWeek, "ww") }}
        </div>
        <div v-if="selectedView === 'month'">
          {{ formatDate(selectedDate, "MMMM yyyy") }}
        </div>
      </div>
      <div class="flex">
        <CalendarNavigation
          :selectedDate="selectedDate"
          :selectedView="selectedView"
          @date-updated="onDateUpdated"
          @view-updated="onViewUpdated"
        />
      </div>
    </div>
    <div class="flex flex-1">
      <slot :selectedDate="selectedDate" :selectedView="selectedView"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from "vue";
import CalendarNavigation from "./CalendarNavigation.vue";
// other
import { formatDate, getFirstDay, addDate } from "@/utils/dateHelpers";

export default defineComponent({
  name: "CalendarLayout",
  components: { CalendarNavigation },
  props: {
    selectedDate: {
      default: null,
      type: Object as PropType<Date>
    },
    selectedView: {
      type: String,
      default: "day",
      validator: (prop: string) => ["day", "week", "month"].includes(prop)
    }
  },
  emits: ["date-updated", "view-updated"],
  setup(props, context) {
    // initialize data

    const firstDayOfWeek = computed(() => getFirstDay(props.selectedDate, "week"));
    const lastDayOfWeek = computed(() => addDate(firstDayOfWeek.value, { days: 5 }));

    const onDateUpdated = (newDate: Date) => {
      context.emit("date-updated", newDate);
    };

    const onViewUpdated = (newView: string) => {
      context.emit("view-updated", newView);
    };

    return { firstDayOfWeek, lastDayOfWeek, formatDate, onDateUpdated, onViewUpdated };
  }
});
</script>
