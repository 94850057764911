<template>
  <div class="flex w-full items-center justify-between space-x-4">
    <div class="flex">
      <span class="relative z-0 inline-flex shadow-sm rounded-md">
        <button
          type="button"
          :class="[navButtonClasses, selectedView === 'day' ? navButtonActiveClasses : navButtonDefaultClasses]"
          class="rounded-l-md px-4 py-2 w-20"
          @click="selectView('day')"
        >
          Day
        </button>
        <button
          type="button"
          :class="[navButtonClasses, selectedView === 'week' ? navButtonActiveClasses : navButtonDefaultClasses]"
          class="-ml-px px-4 py-2 w-20"
          @click="selectView('week')"
        >
          Week
        </button>
        <button
          type="button"
          :class="[navButtonClasses, selectedView === 'month' ? navButtonActiveClasses : navButtonDefaultClasses]"
          class="-ml-px rounded-r-md px-4 py-2 w-20"
          @click="selectView('month')"
        >
          Month
        </button>
      </span>
    </div>

    <DatePickerInput :name="'datePicker'" :buttonOnly="true" :initialDate="selectedDate" @select-date="onSelectDate">
      <button type="button" :class="[navButtonClasses, navButtonDefaultClasses]" class="px-2 py-2 rounded-md">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
      </button>
    </DatePickerInput>

    <div class="flex pr-5">
      <span class="relative z-0 inline-flex shadow-sm rounded-md">
        <button
          type="button"
          :class="[navButtonClasses, navButtonDefaultClasses]"
          class="rounded-l-md px-2 py-2"
          @click="clickPrev"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <button
          type="button"
          :class="[navButtonClasses, navButtonDefaultClasses]"
          class="-ml-px px-4 py-2"
          @click="clickToday"
        >
          Today
        </button>
        <button
          type="button"
          :class="[navButtonClasses, navButtonDefaultClasses]"
          class="-ml-px px-2 py-2 rounded-r-md"
          @click="clickNext"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
// components
import DatePickerInput from "@/components/datepicker/DatePickerInput.vue";
// other
import { addDate, subDate } from "@/utils/dateHelpers";

export default defineComponent({
  name: "CalendarHeaderNav",
  components: { DatePickerInput },
  props: {
    selectedDate: {
      default: null,
      type: Object as PropType<Date>
    },
    selectedView: {
      type: String,
      default: "day",
      validator: (prop: string) => ["day", "week", "month"].includes(prop)
    }
  },
  emits: ["date-updated", "view-updated"],
  setup(props, context) {
    // button styles
    const navButtonClasses =
      "relative inline-flex items-center justify-center border text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500";

    const navButtonDefaultClasses = "bg-white text-gray-500 border-gray-300 hover:bg-gray-50";
    const navButtonActiveClasses = "bg-blue-100 text-blue-700 border-transparent hover:bg-blue-200";

    // add one day to selected day
    const clickNext = () => {
      const newDate = ref(props.selectedDate);
      if (props.selectedView === "day") {
        newDate.value = addDate(props.selectedDate, { days: 1 });
      } else if (props.selectedView === "week") {
        newDate.value = addDate(props.selectedDate, { weeks: 1 });
      } else if (props.selectedView === "month") {
        newDate.value = addDate(props.selectedDate, { months: 1 });
      }
      context.emit("date-updated", newDate.value);
    };

    // subtract one day from selected day
    const clickPrev = () => {
      const newDate = ref(props.selectedDate);
      if (props.selectedView === "day") {
        newDate.value = subDate(props.selectedDate, { days: 1 });
      } else if (props.selectedView === "week") {
        newDate.value = subDate(props.selectedDate, { weeks: 1 });
      } else if (props.selectedView === "month") {
        newDate.value = subDate(props.selectedDate, { months: 1 });
      }
      context.emit("date-updated", newDate.value);
    };

    // select current date
    const clickToday = () => {
      context.emit("date-updated", new Date());
    };

    // select date with date picker
    const onSelectDate = (date: string) => {
      context.emit("date-updated", date);
    };

    // select view
    const selectView = (view: string) => {
      context.emit("view-updated", view);
    };

    return {
      navButtonClasses,
      navButtonDefaultClasses,
      navButtonActiveClasses,
      onSelectDate,
      clickNext,
      clickPrev,
      clickToday,
      selectView
    };
  }
});
</script>
