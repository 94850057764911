<template>
  <div class="flex items-center justify-between bg-yellow-200 h-6">
    <p class="ml-4">{{ task.name }}</p>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { GanttTask } from "@/components/gantt/useGantt";

export default defineComponent({
  name: "GanttListTask",
  components: {},
  props: {
    task: { type: Object as PropType<GanttTask>, required: true }
  },
  setup() {
    return {};
  }
});
</script>
