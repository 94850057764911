
import { defineComponent, PropType } from "vue";
// other
import useGantt, { GanttGroup } from "@/components/gantt/useGantt";

export default defineComponent({
  name: "GanttListGroup",
  components: {},
  props: {
    group: { type: Object as PropType<GanttGroup>, required: true }
  },
  emits: ["toggle-collapse"],
  setup() {
    const { toggleGroupCollapse } = useGantt();

    return { toggleGroupCollapse };
  }
});
