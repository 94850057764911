
import { defineComponent } from "vue";
import { formatDate } from "@/utils/dateHelpers";

export default defineComponent({
  name: "DatePickerHeader",
  props: {
    selectedDate: {
      type: Date,
      required: true,
    },
  },
  emits: {
    "click-next": null,
    "click-prev": null,
  },
  setup(props, context) {
    const onClickNext = () => context.emit("click-next");
    const onClickPrev = () => context.emit("click-prev");

    return { formatDate, onClickNext, onClickPrev };
  },
});
