
import { defineComponent } from "vue";
import { formatDate } from "@/utils/dateHelpers";
import useGantt from "@/components/gantt/useGantt";
import useTimeIntervals from "@/utils/useTimeIntervals";

export default defineComponent({
  name: "GanttGridHeader",
  components: {},
  setup() {
    const { gridColWidth, gridSize } = useGantt();
    const { timeIntervals, timeIntervalSize } = useTimeIntervals();

    // number of time intervals per hour
    const timeIntervalHourRatio = 60 / timeIntervalSize.value;
    // get only the first interval of each hour
    const hourIntervals = [];
    for (let i = 0; i < timeIntervals.value.length; i += timeIntervalHourRatio) {
      hourIntervals.push(timeIntervals.value[i]);
    }

    return {
      formatDate,
      timeIntervals,
      hourIntervals,
      timeIntervalHourRatio,
      gridColWidth,
      gridSize
    };
  }
});
