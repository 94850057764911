<template>
  <GanttListGroup v-if="workAreaGroup" :group="workAreaGroup">
    <template #tasks>
      <GanttListTask v-for="task in workAreaGroup.tasks" :key="task.id" :task="task">
        <div class="flex mr-2 p-1 text-gray-700 cursor-pointer" @click="onDeleteShift(task.id)">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </div>
      </GanttListTask>
    </template>
    <template #menu>
      <div class="flex p-1 mx-1 rounded-full bg-red-100 cursor-pointer" @click="onAddTask(workAreaGroup.id)">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <p class="ml-2">
          Add employee
        </p>
      </div>
    </template>
  </GanttListGroup>
  <WorkAreaDayViewModal
    v-if="workAreaGroup"
    @close="modalVisible = false"
    :visible="modalVisible"
    :group="workAreaGroup"
  />
</template>

<script lang="ts">
import { computed, defineComponent, watch, ref, PropType } from "vue";
// components
import GanttListGroup from "@/components/gantt/list/GanttListGroup.vue";
import GanttListTask from "@/components/gantt/list/GanttListTask.vue";
import WorkAreaDayViewModal from "@/features/schedules/dayView/workArea/list/DayViewWorkAreaModal.vue";
// store
import useScheduleStore from "@/store/useScheduleStore";
// other
import useGantt, { GanttGroup } from "@/components/gantt/useGantt";
import { createWorkAreaTask } from "@/features/schedules/dayView/useScheduleDayView";

export default defineComponent({
  name: "DayViewWorkAreaListGroup",
  components: {
    GanttListGroup,
    GanttListTask,
    WorkAreaDayViewModal
  },
  props: {
    workAreaGroup: { type: Object as PropType<GanttGroup>, required: true }
  },

  setup(props) {
    const { shifts, onDeleteShift, onDeleteShiftSuccess } = useScheduleStore({
      querySelectedSchedule: true
    });
    const { setGanttGroupTasks } = useGantt();

    const workAreaShifts = computed(() => shifts.value.filter(shift => shift.work_area_id == props.workAreaGroup.id));
    const workAreaTasks = computed(() => workAreaShifts.value.map(shift => createWorkAreaTask(shift)));

    // initially set group tasks
    setGanttGroupTasks(props.workAreaGroup.id, workAreaTasks.value);

    // update group tasks
    watch(workAreaTasks, newWorkAreaTasks => {
      setGanttGroupTasks(props.workAreaGroup.id, newWorkAreaTasks);
    });

    /**
     * Add employee
     */

    const modalVisible = ref(false);

    // add employee shift to work area
    const onAddTask = () => {
      modalVisible.value = !modalVisible.value;
    };

    onDeleteShiftSuccess(() => console.info("shift deleted"));

    return { onAddTask, modalVisible, onDeleteShift };
  }
});
</script>
