import { computed, reactive } from "vue";
import useTimeIntervals from "@/utils/useTimeIntervals";
import { ShiftFullFragment, ScheduleWorkAreasView, ScheduleEmployeesView } from "@/graphql/types";

export interface GanttTask {
  id: string;
  name: string;
  data: ShiftFullFragment;
}

export interface GanttGroup {
  id: string;
  name: string;
  isCollapsed: boolean;
  tasks: GanttTask[];
  data: ScheduleWorkAreasView | ScheduleEmployeesView;
}

export interface GanttState {
  groups: GanttGroup[];
}

// Initialize time intercals state
const state: GanttState = reactive({
  groups: []
});

/**
 * Gantt hook
 */
export default function useGantt() {
  // get time interavls
  const { state: timeIntervalsState } = useTimeIntervals();

  /**
   * Gantt groups
   */

  // set groups
  const setGanttGroups = (groups: GanttGroup[]) => {
    state.groups = groups;
  };

  // toggle collapse property
  const toggleGroupCollapse = (id: string) => {
    const index = state.groups.findIndex(group => group.id === id);
    if (index !== -1) {
      state.groups[index].isCollapsed = !state.groups[index].isCollapsed;
    }
  };

  /**
   * Gantt tasks
   */

  // set groups
  const setGanttGroupTasks = (groupId: string, tasks: GanttTask[]) => {
    const index = state.groups.findIndex(group => group.id === groupId);
    if (index !== -1) {
      state.groups[index].tasks = tasks;
    }
  };

  return {
    setGanttGroups,
    setGanttGroupTasks,
    toggleGroupCollapse,
    groups: computed(() => state.groups),
    gridColWidth: computed(() => `${20}px`),
    gridSize: computed(() => timeIntervalsState.timeIntervals.length)
  };
}
