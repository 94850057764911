
import { defineComponent } from "vue";

export default defineComponent({
  name: "GanttGridGroup",
  components: {},
  props: { isCollapsed: { type: Boolean, required: true } },
  setup() {
    return {};
  }
});
