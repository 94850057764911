
import { defineComponent, PropType } from "vue";
import { GanttTask } from "@/components/gantt/useGantt";

export default defineComponent({
  name: "GanttListTask",
  components: {},
  props: {
    task: { type: Object as PropType<GanttTask>, required: true }
  },
  setup() {
    return {};
  }
});
