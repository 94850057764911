
import { defineComponent } from "vue";
import GanttGridBorders from "@/components/gantt/grid/GanttGridBorders.vue";

export default defineComponent({
  name: "GanttGrid",
  components: {
    GanttGridBorders
  },
  props: {},
  setup() {
    return {};
  }
});
