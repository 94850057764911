
import { defineComponent, PropType, computed } from "vue";
import CalendarNavigation from "./CalendarNavigation.vue";
// other
import { formatDate, getFirstDay, addDate } from "@/utils/dateHelpers";

export default defineComponent({
  name: "CalendarLayout",
  components: { CalendarNavigation },
  props: {
    selectedDate: {
      default: null,
      type: Object as PropType<Date>
    },
    selectedView: {
      type: String,
      default: "day",
      validator: (prop: string) => ["day", "week", "month"].includes(prop)
    }
  },
  emits: ["date-updated", "view-updated"],
  setup(props, context) {
    // initialize data

    const firstDayOfWeek = computed(() => getFirstDay(props.selectedDate, "week"));
    const lastDayOfWeek = computed(() => addDate(firstDayOfWeek.value, { days: 5 }));

    const onDateUpdated = (newDate: Date) => {
      context.emit("date-updated", newDate);
    };

    const onViewUpdated = (newView: string) => {
      context.emit("view-updated", newView);
    };

    return { firstDayOfWeek, lastDayOfWeek, formatDate, onDateUpdated, onViewUpdated };
  }
});
