<template>
  <transition
    enter-active-class="ease-out duration-75"
    enter-from-class="opacity-0 scale-95"
    enter-to-class="opacity-100 scale-100"
    leave-active-class="ease-in duration-75"
    leave-from-class="opacity-100 scale-100"
    leave-to-class="opacity-0 scale-95"
  >
    <div class="bg-white rounded-xl shadow-lg overflow-auto" v-if="visible">
      <div class="p-4 sm:p-6">
        <DatePickerHeader
          :selectedDate="selectedMonth"
          @click-prev="onSelectPrevMonth"
          @click-next="onSelectNextMonth"
        />
        <div class="flex justify-between">
          <div class="flex justify-center items-center w-10 h-10" v-for="(day, index) in days" :key="index">
            <div class="text-sm text-gray-600">{{ day }}</div>
          </div>
        </div>
        <div class="flex flex-col space-y-1">
          <div class="flex flex-row justify-between" v-for="(week, index) in weeks" :key="index">
            <div class="flex" v-for="(weekDay, index) in getWeekDays(week)" :key="index">
              <DatePickerDay
                :date="weekDay"
                :selectedDate="selectedDate"
                :selectedMonth="selectedMonth"
                :lastDisabledDate="lastDisabledDate"
                :firstDisabledDate="firstDisabledDate"
                @click-date="onSelectDate(weekDay)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref, watch } from "vue";
import DatePickerDay from "./DatePickerDay.vue";
import DatePickerHeader from "./DatePickerHeader.vue";
import { formatDate, addDate, subDate, getWeekDays, getMonthWeeksStartDays } from "@/utils/dateHelpers";

export default defineComponent({
  name: "DatePicker",
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    initialDate: {
      default: null,
      type: Object as PropType<Date>
    },
    focusMonth: {
      default: null,
      type: Object as PropType<Date>
    },
    lastDisabledDate: {
      default: null,
      type: Object as PropType<Date>
    },
    firstDisabledDate: {
      default: null,
      type: Object as PropType<Date>
    }
  },
  components: {
    DatePickerDay,
    DatePickerHeader
  },
  emits: {
    "select-date": null
  },
  setup(props, context) {
    // initial dates
    const selectedDate = ref<null | Date>(props.initialDate);
    const selectedMonth = ref(props.focusMonth);

    if (selectedMonth.value === null) {
      selectedMonth.value = new Date();
    }

    // change initial month to be displayed in calendar
    watch(
      () => props.focusMonth,
      curr => {
        selectedMonth.value = curr;
      }
    );

    // change initial date to be displayed in calendar
    watch(
      () => props.initialDate,
      curr => {
        selectedDate.value = curr;
      }
    );

    // format week days string
    const initWeekDays = getWeekDays(selectedMonth.value);
    const days = initWeekDays.map((day: Date) => formatDate(day, "iiiiii"));
    // weeks of selected month
    const weeks = computed(() => getMonthWeeksStartDays(selectedMonth.value));

    // select date, change selected month if date outside current month is clicked
    const onSelectDate = (date: Date) => {
      selectedDate.value = date;
      selectedMonth.value = date;
      context.emit("select-date", date);
    };

    // month navigation
    const onSelectPrevMonth = () => (selectedMonth.value = subDate(selectedMonth.value, { months: 1 }));
    const onSelectNextMonth = () => (selectedMonth.value = addDate(selectedMonth.value, { months: 1 }));

    return {
      days,
      weeks,
      getWeekDays,
      getMonthWeeksStartDays,
      selectedMonth,
      selectedDate,
      onSelectPrevMonth,
      onSelectNextMonth,
      onSelectDate
    };
  }
});
</script>

<style></style>
