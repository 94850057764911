
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "WorkAreaDemandBarItem",
  props: {
    demand: {
      type: Number,
      required: true
    },
    coverage: {
      type: Number,
      required: true
    },
    isFirstItem: {
      type: Boolean,
      required: true
    },
    isLastItem: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const uncoveredDemand = computed(() => props.demand - props.coverage);

    const colorClasses = computed(() => {
      // no demand
      if (props.demand === 0) {
        // no demand and staffed
        if (props.coverage > 0) return "text-white bg-blue-500 border-blue-800 ";
        else return "bg-white border-gray-400";
        // demand
      } else {
        // minimum demand fully covered
        if (props.demand === props.coverage) return "bg-green-200 border-green-500";
        // minimum demand fully unvocered
        else if (props.coverage === 0) return "text-white bg-red-500 border-red-800";
        // minimum demand overstaffed
        else if (props.coverage > props.demand) return "bg-blue-200 border-blue-500";
        // minimum demand partly unvocered
        else return "bg-yellow-200 border-yellow-400";
      }
    });

    const sideClasses = computed(() => {
      return {
        "border-l rounded-l-md": props.isFirstItem == true,
        "border-r rounded-r-md": props.isLastItem == true
      };
    });

    return { uncoveredDemand, colorClasses, sideClasses };
  }
});
