
import { defineComponent, onMounted, ref } from "vue";
// components
import GanttGridHeader from "@/components/gantt/grid/GanttGridHeader.vue";
import GanttGrid from "@/components/gantt/grid/GanttGrid.vue";
import GanttList from "@/components/gantt/list/GanttList.vue";

export default defineComponent({
  name: "GanttChart",
  components: { GanttGridHeader, GanttGrid, GanttList },

  setup() {
    const ganttList = ref<HTMLElement | null>(null);
    const ganttGridHeader = ref<HTMLElement | null>(null);
    const ganttGrid = ref<HTMLElement | null>(null);
    const scrollbarWidth = ref(0);

    // set scrollbar width for header padding
    const setScrollbarWidth = () => {
      if (ganttGrid.value && ganttGridHeader.value) {
        scrollbarWidth.value = ganttGrid.value.offsetWidth - ganttGrid.value.clientWidth;
      }
    };

    onMounted(() => {
      // reset scroll sync
      if (ganttGridHeader.value && ganttList.value) {
        ganttGridHeader.value.scrollTop = 0;
        ganttGridHeader.value.scrollLeft = 0;
      }

      // set initial scrollbar width
      setScrollbarWidth();
      // update scrollbar width on resize
      window.onresize = setScrollbarWidth;
    });

    // Sync scroll of task list and chart header
    const syncScroll = (event: { target: HTMLInputElement }) => {
      if (ganttGridHeader.value && ganttList.value) {
        ganttList.value.scrollTop = event.target.scrollTop;
        ganttGridHeader.value.scrollLeft = event.target.scrollLeft;
      }
    };

    return { syncScroll, ganttList, ganttGridHeader, ganttGrid, scrollbarWidth, setScrollbarWidth };
  }
});
