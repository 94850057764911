<template>
  <div class="flex flex-1 flex-col relative">
    <div class="flex-1 relative pt-2 py-6">
      <GanttGridBorders />
      <div class="space-y-2">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import GanttGridBorders from "@/components/gantt/grid/GanttGridBorders.vue";

export default defineComponent({
  name: "GanttGrid",
  components: {
    GanttGridBorders
  },
  props: {},
  setup() {
    return {};
  }
});
</script>
