<template>
  <div class="flex flex-1 flex-col overflow-hidden">
    <div class="flex flex-shrink-0 h-10 overflow-hidden">
      <div class="bg-green-100 w-56">
        <div class="flex justify-center">
          <p>
            Some content
          </p>
        </div>
      </div>
      <div ref="ganttGridHeader" class="flex-1 overflow-hidden" :style="{ 'padding-right': scrollbarWidth + 'px' }">
        <GanttGridHeader />
      </div>
    </div>
    <div class="flex flex-1 bg-white overflow-hidden">
      <div ref="ganttList" class="w-56 pt-2 overflow-hidden bg-green-400">
        <GanttList><slot name="list"></slot></GanttList>
      </div>
      <div ref="ganttGrid" class="flex flex-1 overflow-auto" @scroll="syncScroll">
        <GanttGrid><slot name="grid"></slot></GanttGrid>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
// components
import GanttGridHeader from "@/components/gantt/grid/GanttGridHeader.vue";
import GanttGrid from "@/components/gantt/grid/GanttGrid.vue";
import GanttList from "@/components/gantt/list/GanttList.vue";

export default defineComponent({
  name: "GanttChart",
  components: { GanttGridHeader, GanttGrid, GanttList },

  setup() {
    const ganttList = ref<HTMLElement | null>(null);
    const ganttGridHeader = ref<HTMLElement | null>(null);
    const ganttGrid = ref<HTMLElement | null>(null);
    const scrollbarWidth = ref(0);

    // set scrollbar width for header padding
    const setScrollbarWidth = () => {
      if (ganttGrid.value && ganttGridHeader.value) {
        scrollbarWidth.value = ganttGrid.value.offsetWidth - ganttGrid.value.clientWidth;
      }
    };

    onMounted(() => {
      // reset scroll sync
      if (ganttGridHeader.value && ganttList.value) {
        ganttGridHeader.value.scrollTop = 0;
        ganttGridHeader.value.scrollLeft = 0;
      }

      // set initial scrollbar width
      setScrollbarWidth();
      // update scrollbar width on resize
      window.onresize = setScrollbarWidth;
    });

    // Sync scroll of task list and chart header
    const syncScroll = (event: { target: HTMLInputElement }) => {
      if (ganttGridHeader.value && ganttList.value) {
        ganttList.value.scrollTop = event.target.scrollTop;
        ganttGridHeader.value.scrollLeft = event.target.scrollLeft;
      }
    };

    return { syncScroll, ganttList, ganttGridHeader, ganttGrid, scrollbarWidth, setScrollbarWidth };
  }
});
</script>
