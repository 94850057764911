
import { watch, defineComponent } from "vue";
// components
import GanttChart from "@/components/gantt/GanttChart.vue";

import DayViewWorkAreaList from "@/features/schedules/dayView/workArea/list/DayViewWorkAreaList.vue";
import DayViewWorkAreaGrid from "@/features/schedules/dayView/workArea/grid/DayViewWorkAreaGrid.vue";
// store
import { PlanningView } from "@/store/useScheduleStore";
// other
import useScheduleDayView from "./useScheduleDayView";

export default defineComponent({
  name: "ScheduleDayView",
  components: {
    GanttChart,
    DayViewWorkAreaList,
    DayViewWorkAreaGrid
  },
  props: {},
  setup() {
    const { planningView, setPlanningViewGroups } = useScheduleDayView();

    // initial group setting
    setPlanningViewGroups(planningView.value);

    // reinitialize groups when planning view changes
    watch(planningView, newPlanningView => {
      setPlanningViewGroups(newPlanningView);
    });

    return { planningView, PlanningView };
  }
});
