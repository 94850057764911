
import { computed, defineComponent, watch, ref, PropType } from "vue";
// components
import GanttListGroup from "@/components/gantt/list/GanttListGroup.vue";
import GanttListTask from "@/components/gantt/list/GanttListTask.vue";
import WorkAreaDayViewModal from "@/features/schedules/dayView/workArea/list/DayViewWorkAreaModal.vue";
// store
import useScheduleStore from "@/store/useScheduleStore";
// other
import useGantt, { GanttGroup } from "@/components/gantt/useGantt";
import { createWorkAreaTask } from "@/features/schedules/dayView/useScheduleDayView";

export default defineComponent({
  name: "DayViewWorkAreaListGroup",
  components: {
    GanttListGroup,
    GanttListTask,
    WorkAreaDayViewModal
  },
  props: {
    workAreaGroup: { type: Object as PropType<GanttGroup>, required: true }
  },

  setup(props) {
    const { shifts, onDeleteShift, onDeleteShiftSuccess } = useScheduleStore({
      querySelectedSchedule: true
    });
    const { setGanttGroupTasks } = useGantt();

    const workAreaShifts = computed(() => shifts.value.filter(shift => shift.work_area_id == props.workAreaGroup.id));
    const workAreaTasks = computed(() => workAreaShifts.value.map(shift => createWorkAreaTask(shift)));

    // initially set group tasks
    setGanttGroupTasks(props.workAreaGroup.id, workAreaTasks.value);

    // update group tasks
    watch(workAreaTasks, newWorkAreaTasks => {
      setGanttGroupTasks(props.workAreaGroup.id, newWorkAreaTasks);
    });

    /**
     * Add employee
     */

    const modalVisible = ref(false);

    // add employee shift to work area
    const onAddTask = () => {
      modalVisible.value = !modalVisible.value;
    };

    onDeleteShiftSuccess(() => console.info("shift deleted"));

    return { onAddTask, modalVisible, onDeleteShift };
  }
});
