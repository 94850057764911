
import { defineComponent } from "vue";

export default defineComponent({
  name: "GanttList",
  components: {},
  props: {},
  setup() {
    return {};
  }
});
