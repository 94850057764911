
import { defineComponent, computed } from "vue";
// components
import DayViewWorkAreaListGroup from "./DayViewWorkAreaListGroup.vue";
// store
import useScheduleStore from "@/store/useScheduleStore";
// other
import useGantt from "@/components/gantt/useGantt";

export default defineComponent({
  name: "DayViewWorkAreaList",
  components: { DayViewWorkAreaListGroup },
  setup() {
    const { groups } = useGantt();
    const { demands } = useScheduleStore();

    // filter groups that have no demand
    const workAreaGroups = computed(() => {
      const workAreaGroups = groups.value.filter(group => {
        const demandList = demands.value?.filter(demand => demand.work_area_id == group.id);
        if (demandList.length > 0) return true;
        else return false;
      });
      return workAreaGroups;
    });

    return { workAreaGroups };
  }
});
