// resizing div element: https://www.youtube.com/watch?v=4qyuNBlc8ho&t=848s
import { ref, Ref } from "vue";

/**
 **************** Helper functions ******************
 */

/**
 * Set left style property of item
 */
const setLeft = (item: Ref<HTMLElement | null>, leftValue: number) => {
  if (item.value) {
    item.value.style.left = leftValue + "px";
  }
};

/**
 * Set width style property of item
 */
const setWidth = (item: Ref<HTMLElement | null>, widthValue: number, auto = false) => {
  if (item.value) {
    if (auto === true) item.value.style.width = "auto";
    else item.value.style.width = widthValue + "px";
  }
};

/**
 * Set position style property of item
 */
const setPosition = (item: Ref<HTMLElement | null>, position: "relative" | "static") => {
  if (item.value) {
    item.value.style.position = position;
  }
};

/**
 * Calculate new start grid column based on coordinates
 */
const calculateNewStartCol = (leftCoordinate: number, gridColWidth: number, prevStartCol: number) => {
  return Math.round(leftCoordinate / gridColWidth) + prevStartCol;
};

/**
 * Calculate new start grid span based on div width
 */
const calculateNewColSpan = (prevWidth: number, gridColWidth: number) => {
  return Math.round(prevWidth / gridColWidth);
};

/**
 * Create array that represents the covered task range
 * Example: size=4, start=1, duration=2
 * [1,1,0,0]
 */
export const createCoverageArray = (size: number, start: number, duration: number) => {
  const coverageArray = [];
  for (let i = 0; i < size; i++) {
    // -1 due to grind index starting at 1, -2 due to start index and start index and first duration double count
    if (i >= start - 1 && i <= start + duration - 2) {
      coverageArray.push(1);
    } else coverageArray.push(0);
  }
  return coverageArray;
};

/**
 * Get width of selected div
 */
const getRectWidth = (item: Ref<HTMLElement | null>) => {
  if (item.value) {
    const rect = item.value.getBoundingClientRect();
    return rect.width;
  } else return 0;
};

/**
 * Calculate move direction
 */
const calculateMoveDirection = (prevX: number, clientX: number) => {
  return prevX - clientX;
};

/**
 * Calculate new left coordinate
 */
const calculateNewLeft = (prevLeft: number, prevX: number, clientX: number) => {
  return prevLeft - calculateMoveDirection(prevX, clientX);
};

/**
 * Initialize all relevant parameters for drag&drop event
 */
const initializeDragDropEventVariables = (data: {
  item: Ref<HTMLElement | null>;
  type: "resize" | "move";
  clientX: number;
}) => {
  // Calculate initial div width and column width
  const prevWidth = getRectWidth(data.item);
  const prevLeft = 0;
  const prevX = data.clientX;

  setPosition(data.item, "relative");
  setLeft(data.item, 0);
  setWidth(data.item, prevWidth);

  return { prevWidth, prevLeft, prevX };
};

/**
 * Initialize all relevant parameters for drag&drop event
 */
const initializeDragDropEventConstants = (data: {
  prevWidth: number;
  startCol: number;
  colSpan: number;
  gridSize: number;
}) => {
  // width of each grid column
  const gridColWidth = data.prevWidth / data.colSpan;
  // max allowed width (right bound)
  const maxWidth = (data.gridSize - data.startCol + 1) * gridColWidth; // Max width based on start of grid-column-start and end of grid
  // min left coordiate (left bound)
  const minLeft = (1 - data.startCol) * gridColWidth; // left border of grid. Current left=0, minLeft corresponds to negative offset
  // max left coordiate (right bound)
  const maxLeft = (data.gridSize - data.startCol + 1) * gridColWidth - data.prevWidth;

  return { gridColWidth, maxWidth, minLeft, maxLeft };
};

/**
 * Check if valid html element is selected
 */
const itemExists = (item: Ref<HTMLElement | null>) => {
  if (item.value === null) {
    console.error("No valid drag and drop item!");
  }
};

/**
 **************** Hook ******************
 */

/**
 * Handle resizing and moving of grid elements
 */
export default function useGanttTask(
  item: Ref<HTMLElement | null>,
  gridData: {
    gridSize: Ref<number>;
    startCol: Ref<number>;
    colSpan: Ref<number>;
  }
) {
  // initialize coverage array
  const { startCol, colSpan, gridSize } = gridData;
  const coverageArray = ref(createCoverageArray(gridSize.value, startCol.value, colSpan.value));
  // initialize current start col and duiration
  const newStart = ref(startCol.value);
  const newSpan = ref(colSpan.value);
  const updateFinished = ref(new Date());

  /**
   * Move gantt bar
   */
  const onMove = (e: MouseEvent) => {
    itemExists(item);
    // extract grid data
    const { startCol, colSpan, gridSize } = gridData;
    // initialize event cariables
    let { prevWidth, prevLeft, prevX } = initializeDragDropEventVariables({
      item: item,
      type: "move",
      clientX: e.clientX
    });
    // initialize event constants
    const { gridColWidth, minLeft, maxLeft } = initializeDragDropEventConstants({
      prevWidth: prevWidth,
      startCol: startCol.value,
      colSpan: colSpan.value,
      gridSize: gridSize.value
    });

    /**
     * Mouse move event to resize the item
     * Resize can happen either towards the right side (right resize handle) or left side (left resize handle)
     */
    const mousemove = (e: MouseEvent) => {
      // calculate new left coordinate
      const newLeft = calculateNewLeft(prevLeft, prevX, e.clientX);
      // update only if width is in valid boundaries
      if (newLeft >= minLeft && newLeft <= maxLeft) {
        // update div props
        setLeft(item, newLeft);
        // new start column
        newStart.value = calculateNewStartCol(newLeft, gridColWidth, startCol.value);
        // update coverage array
        coverageArray.value = createCoverageArray(gridSize.value, newStart.value, colSpan.value);
      }

      // update values after each move
      prevX = e.clientX;
      prevWidth = Number(item.value?.style.width.replace("px", ""));
      prevLeft = Number(item.value?.style.left.replace("px", ""));
    };

    /**
     * Update parameters at end of drag&drop event
     */
    const mouseup = () => {
      // update start column
      startCol.value = calculateNewStartCol(prevLeft, gridColWidth, startCol.value);

      // update coverage array
      coverageArray.value = createCoverageArray(gridSize.value, startCol.value, colSpan.value);
      // set update finished flag (indicating mouse up to vue component)
      updateFinished.value = new Date();
      // reset grid item parameters
      setPosition(item, "static");
      setWidth(item, 0, true);

      // remove event listeners
      window.removeEventListener("mousemove", mousemove);
      window.removeEventListener("mouseup", mouseup);
    };

    /**
     * initialize event listeners for mouse move and release
     */
    window.addEventListener("mousemove", mousemove);
    window.addEventListener("mouseup", mouseup);
  };

  /**
   * Resize gantt bar
   */
  const onResize = (resizeDirection: "left" | "right", e: MouseEvent) => {
    itemExists(item);
    // extract grid data
    const { startCol, colSpan, gridSize } = gridData;
    // initialize event cariables
    let { prevWidth, prevLeft, prevX } = initializeDragDropEventVariables({
      item: item,
      type: "resize",
      clientX: e.clientX
    });
    // initialize event constants
    const { gridColWidth, minLeft, maxWidth } = initializeDragDropEventConstants({
      prevWidth: prevWidth,
      startCol: startCol.value,
      colSpan: colSpan.value,
      gridSize: gridSize.value
    });
    // initialize prevStartCol and prevCol span to prevent update bug
    let prevValidStartCol = startCol.value;
    let prevValidColSpan = colSpan.value;

    /**
     * Mouse move event to resize the item
     * Resize can happen either towards the right side (right resize handle) or left side (left resize handle)
     */
    const mousemove = (e: MouseEvent) => {
      // previous div coordinates
      if (resizeDirection === "left") {
        // adjust the div coordinates
        const newLeft = calculateNewLeft(prevLeft, prevX, e.clientX);
        const newWidth = prevWidth + (prevX - e.clientX);
        // update only if width is in valid boundaries
        if (newWidth >= gridColWidth && newLeft >= minLeft) {
          setLeft(item, newLeft);
          setWidth(item, newWidth);
          // calculate new start column and column span
          const newStart = calculateNewStartCol(newLeft, gridColWidth, startCol.value);
          newSpan.value = calculateNewColSpan(newWidth, gridColWidth);
          // bug: within the range of 1px, the span gets updated without the start being updated
          // solution: only update if both values have changed
          if (newStart !== prevValidStartCol && newSpan.value !== prevValidColSpan) {
            // update coverage array
            coverageArray.value = createCoverageArray(gridSize.value, newStart, newSpan.value);
            // update prevValidValues to prevent bug
            prevValidStartCol = newStart;
            prevValidColSpan = newSpan.value;
          }
        }
      } else if (resizeDirection === "right") {
        // increase the width
        const newWidth = prevWidth - (prevX - e.clientX);
        // update only if width is in valid boundaries
        if (newWidth >= gridColWidth && newWidth <= maxWidth) {
          setWidth(item, newWidth);
          // calculate new span
          newSpan.value = calculateNewColSpan(newWidth, gridColWidth);
          prevValidColSpan = newSpan.value;
          // update coverage array
          coverageArray.value = createCoverageArray(gridSize.value, startCol.value, newSpan.value);
        }
      }

      // update values after each move
      prevX = e.clientX;
      prevWidth = Number(item.value?.style.width.replace("px", ""));
      prevLeft = Number(item.value?.style.left.replace("px", ""));
    };

    /**
     * Update parameters at end of resize event
     */
    const mouseup = () => {
      // calculate new grid parameters based on final item properties
      if (resizeDirection === "left") {
        // calculate new start time
        startCol.value = prevValidStartCol;
      }
      // calculate new col span
      colSpan.value = prevValidColSpan;
      // update coverage array
      coverageArray.value = createCoverageArray(gridSize.value, startCol.value, colSpan.value);
      // set update finished flag (indicating mouse up to vue component)
      updateFinished.value = new Date();
      // reset item parameters
      setPosition(item, "static");
      setWidth(item, 0, true);

      // remove event listeners
      window.removeEventListener("mousemove", mousemove);
      window.removeEventListener("mouseup", mouseup);
    };

    /**
     * initialize event listeners for mouse move and release
     */
    window.addEventListener("mousemove", mousemove);
    window.addEventListener("mouseup", mouseup);
  };
  return { onMove, onResize, coverageArray, newSpan, updateFinished };
}
