
import { defineComponent } from "vue";
// components
import CalendarLayout from "@/components/calendar/CalendarLayout.vue";
import ScheduleDayView from "@/features/schedules/dayView/ScheduleDayView.vue";
// store
import useScheduleStore, { CalendarView } from "@/store/useScheduleStore";

export default defineComponent({
  name: "ScheduleCalendar",
  components: {
    CalendarLayout,
    ScheduleDayView
  },
  setup() {
    const { calendarView, selectedDate, setCalendarView, setDate } = useScheduleStore();

    const onDateUpdated = (newDate: Date) => {
      setDate(newDate);
    };

    const onViewUpdated = (newView: CalendarView) => {
      setCalendarView(newView);
    };

    return {
      selectedDate,
      calendarView,
      onDateUpdated,
      onViewUpdated,
      CalendarView
    };
  }
});
