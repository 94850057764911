<template>
  <FormModal
    :visible="visible"
    :clickOutside="true"
    :header="'Add employee'"
    :size="'xl'"
    :fullHeigth="false"
    @close="onClose"
  >
    <template #content>
      <div v-if="group" class="flex flex-col space-y-2">
        <div class="flex" v-for="employee in workAreaEmployees" :key="employee.id">
          <div
            class="p-2 rounded-lg bg-blue-100"
            :class="[
              getEmployeeStatus(employee.id) == EmployeeStatus.Available ? 'cursor-pointer' : 'cursor-not-allowed'
            ]"
            @click="onAddEmployee(employee.id)"
          >
            {{ createFullName(employee.first_name, employee.last_name) }}
          </div>
          <div class="ml-2 flex items-center">
            <p
              v-if="getEmployeeStatus(employee.id) == EmployeeStatus.Assigned"
              class="px-2 py-1 rounded-full bg-gray-300"
            >
              Already assigned
            </p>
            <p
              v-if="getEmployeeStatus(employee.id) == EmployeeStatus.Available"
              class="px-2 py-1 rounded-full bg-green-300"
            >
              Availbale
            </p>
          </div>
        </div>
      </div>
    </template>
  </FormModal>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
// components
import FormModal from "@/components/modals/FormModal.vue";
// store
import useScheduleStore from "@/store/useScheduleStore";
import useEmployeeWorkAreaStore from "@/store/useEmployeeWorkAreaStore";
// other
import { GanttGroup } from "@/components/gantt/useGantt";
import { createFullName } from "@/store/useEmployeeStore";
import { parseTimeString } from "@/utils/dateHelpers";

export enum EmployeeStatus {
  Available = "available",
  Assigned = "assigned",
  Absent = "absent"
}

export default defineComponent({
  name: "DayViewWorkAreaModal",
  components: {
    FormModal
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    group: { type: Object as PropType<GanttGroup>, required: true }
  },
  emits: ["close"],

  setup(props, context) {
    // store
    const { selectedDate, employees, onCreateShift, onCreateShiftSuccess } = useScheduleStore({
      querySelectedSchedule: true
    });
    const { employeeWorkAreas } = useEmployeeWorkAreaStore({ allEmployeeWorkAreas: true });

    // close modal
    const onClose = () => {
      context.emit("close");
    };

    // get assignments of considered work area
    const employeeWorkAreasFiltered = computed(() =>
      employeeWorkAreas.value.filter(item => item.work_area_id === props.group.id)
    );
    // get all employees that are allowed to work in this work area
    const employeeIds = computed(() => employeeWorkAreasFiltered.value.map(item => item.employee_id));
    const workAreaEmployees = computed(() =>
      employees.value?.filter(employee => employeeIds.value?.includes(employee.id))
    );

    // employees that are currently assigned to group
    const shiftsEmployeeIds = computed(() => props.group.tasks.map(task => task.data.employee_id));

    // check if employee is already assigned to shift or is still available
    // can also include check if employee is absent
    const getEmployeeStatus = (employeeId: string) => {
      if (shiftsEmployeeIds.value.includes(employeeId)) return EmployeeStatus.Assigned;
      else return EmployeeStatus.Available;
    };

    // Validate and create shift
    const onAddEmployee = (employeeId: string) => {
      // cancel if employee is not available
      if (getEmployeeStatus(employeeId) != EmployeeStatus.Available) return;
      // create shift
      onCreateShift(
        parseTimeString("07:00", selectedDate.value),
        parseTimeString("15:00", selectedDate.value),
        employeeId,
        props.group.id
      );
    };

    onCreateShiftSuccess(() => {
      console.info("shift created");
      onClose();
    });

    return {
      createFullName,
      workAreaEmployees,
      shiftsEmployeeIds,
      getEmployeeStatus,
      EmployeeStatus,
      onAddEmployee,
      onClose
    };
  }
});
</script>
