<template>
  <div class="flex flex-col">
    <slot name="group-bar"></slot>
    <div v-show="!isCollapsed" class="space-y-2 mt-2 mb-12 z-10">
      <slot name="task-bars"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "GanttGridGroup",
  components: {},
  props: { isCollapsed: { type: Boolean, required: true } },
  setup() {
    return {};
  }
});
</script>
