<template>
  <PageLayout :headerText="headerText">
    <template #header-addon-left>
      <router-link :to="prevRoute" class="flex items-center p-0.5 mr-2">
        <div class="h-5 w-5 text-gray-500">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M15 19l-7-7 7-7" />
          </svg>
        </div>
      </router-link>
    </template>
    <template #main>
      <div v-if="schedule" class="flex flex-1 flex-col overflow-hidden">
        <ScheduleCalendar />
      </div>
    </template>
  </PageLayout>
</template>

<script lang="ts">
import { defineComponent, computed, watch } from "vue";
import { useRoute } from "vue-router";
import PageLayout from "@/components/PageLayout.vue";
import ScheduleCalendar from "@/features/schedules/ScheduleCalendar.vue";
// import WorkAreaDayView from "@/features/schedules/workAreaDayView/WorkAreaDayView.vue";
// store
import useScheduleStore from "@/store/useScheduleStore";
// other
import { repairUuid } from "@/utils/globalHelpers";

export default defineComponent({
  name: "SchedulePlanningPage",
  components: {
    PageLayout,
    ScheduleCalendar
  },
  setup() {
    // schedule id from link
    const {
      params: { scheduleId: scheduleUrlId }
    } = useRoute();
    const scheduleId = computed(() => repairUuid(scheduleUrlId));
    // store
    const { schedule, setSelectedSchedule } = useScheduleStore({ scheduleId: scheduleId.value });

    // set selected schedule
    if (schedule.value) setSelectedSchedule(schedule.value);
    watch(schedule, () => {
      if (schedule.value) setSelectedSchedule(schedule.value);
    });

    // get previous route, that was not schedule details related
    const prevRoute = computed(() => {
      if (window.history.state.back === null || window.history.state.back.includes("new")) {
        return "/schedules";
      } else return window.history.state.back;
    });

    const headerText = computed(() => {
      if (schedule.value) return schedule.value.name;
      else return "";
    });

    return {
      headerText,
      scheduleId,
      prevRoute,
      schedule
    };
  }
});
</script>
